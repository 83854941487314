var FormTable = (function () {
    var _private = {};

    _private.instance = this;

    _private.sortOptions = {
        items: '.form-table-line',
        axis:  'y',
        stop:  function (event, ui) {
            _private.instance.reorderList($(event.target));
            if(_private.fireInputEvents){
                ui.item.find('textarea,input[type=text]').blur();
            }
        }
    };

    _private.formTable = undefined;

    _private.fireInputEvents = true;

    /**
     * @param {*} formTable
     */
    this.init = function (formTable) {
        _private.formTable = formTable;
        _private.bindEvents(formTable);
        return this;
    };

    /**
     * @param {*} formTable
     */
    _private.bindEvents = function (formTable) {
        if (!formTable.hasClass('form-table')) {
            formTable = formTable.find('.form-table');
        }
        _private.instance.bindFormTable(formTable);
        _private.initSortable(formTable);
    };

    /**
     * @param {*} formTable
     */
    this.bindFormTable = function (formTable) {
        formTable.find('input,textarea').unbind('blur.checkAndAppend');
        formTable.find('input,textarea').unbind('blur.checkAndRemove');
        formTable.find('div.add-row').find('span').unbind('click.addLine').on('click.addLine',
            function (event) {
                _private.instance.addLine(event.target);
            }
        );
        formTable.find('div.remove-row').find('span').unbind('click.checkAndRemove').on('click.checkAndRemove',
            function (event) {
                _private.instance.checkAndRemove(event.target);
            }
        );
        formTable.find('.form-table-line').find('.indicator')
                 .unbind('blur.checkAndRemove')
                 .on('blur.checkAndRemove',
                     function (event) {
                         _private.instance.checkAndRemove(event.target);
                     }
                 );
    };

    /**
     *
     * @param {*} formTable
     */
    _private.initSortable = function (formTable) {
        if(!formTable.hasClass('sortable')){
            return;
        }
        formTable.sortable(_private.sortOptions);
    };

    /**
     *
     * @param {*} trigger
     */
    this.addLine = function (trigger) {
        var line      = $(trigger).parents('.form-table-line');
        var lineClone = $(line).clone(true);
        lineClone.removeClass('protected');
        lineClone.find('textarea,input[type=text]').val('');
        lineClone.find('input[type=checkbox]').prop('checked', false);
        lineClone.find('textarea,input,select').each(function (i, inp) {
            inp      = $(inp);
            var id   = inp.attr('id');
            var name = inp.attr('name');
            inp.attr('disabled', false);
            if (!_.isUndefined(id)) {
                inp.attr('id', id.replace('expand_', ''));
            }
            if (!_.isUndefined(name)) {
                inp.attr('name', name.replace('expand_', ''));
            }
        });
        $(line).find('label').each(function (i, label) {
            $(label).attr('for', $(label).attr('for').replace('expand_', ''));
        });

        var list = $(trigger).parents('.form-table');
        //list.append(lineClone);
        lineClone.insertAfter(line);
        _private.instance.reorderList(list);
        new FormObserver(line.parents('form')).addElement(lineClone.find('textarea,input[id],select'));
        _private.bindEvents(line.parents('.form-table'));
        if(_private.fireInputEvents){
            lineClone.find('textarea,input[type=text]').blur();
            lineClone.find('select').change();
            lineClone.find('textarea,input[type=text]').first().focus();
        }
        var index = list.find('.form-table-line').index(line);
        line.parents('.form-table').trigger('formTableElement').trigger('formTableElementAdd',[index,lineClone]);
    };

    /**
     *
     * @param {*} input
     */
    this.checkAndRemove = function (input) {
        input = $(input);
        if ($.trim(input.val()) !== '') {
            return;
        }
        var line = input.parents('.form-table-line');
        if (line.hasClass('inactive') || line.hasClass('protected')) {
            return;
        }
        var list  = input.parents('.form-table');
        var index = list.find('.form-table-line').index(line);
        if (list.find('.form-table-line').length < 2) {
            list.trigger('formTableElement').trigger('formTableElementRemove', [index,line]);
            return;
        }
        new FormObserver(line.parents('form')).removeElement(line.find('textarea,input[id],select'));
        line.remove();
        _private.instance.reorderList(list);
        if(_private.fireInputEvents){
            list.find('textarea,input[type=text]').first().blur();
        }
        list.trigger('formTableElement').trigger('formTableElementRemove',[index]);
    };

    /**
     *
     * @param {*} list
     */
    this.reorderList = function (list) {
        list = list || _private.formTable;
        $(list).find('.form-table-line').each(function (i, line) {
            $(line).find('textarea,input,select').each(function (l, inp) {
                inp      = $(inp);
                var id   = inp.attr('id');
                var name = inp.attr('name');
                if (!_.isUndefined(id)) {
                    inp.attr('id', id.replace(/\d+/, i));
                }
                if (!_.isUndefined(name)) {
                    inp.attr('name', name.replace(/\d+/, i));
                }
            });
            $(line).find('label').each(function (l, label) {
                label            = $(label);
                var forAttribute = label.attr('for');
                if (!_.isUndefined(forAttribute)) {
                    label.attr('for', forAttribute.replace(/\d+/, i));
                }
            });
        });
        return this;
    };

    this.setReorderMethod = function (reorderMethod) {
        _private.instance.reorderList = reorderMethod;
        return _private.instance;
    };

    this.setSortOptions = function (options) {
        _private.sortOptions = options;
        return _private.instance;
    };

    this.setFireInputEvents = function (fireInputEvents) {
        _private.fireInputEvents = fireInputEvents;
        return _private.instance;
    };

    this.getSortOptions = function () {
        return _private.sortOptions;
    };
});
